import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAppContext } from "../../context/app-context";

import OrderHistory from "../../screens/order-history";
import Orders from "../../screens/orders";
import Settings from "../../screens/settings";

export default function AppRoutes() {
  const { loading } = useAppContext();

  if (loading) return <h1>Loading...</h1>; //<LoadingOverlay />;

  return (
    <Routes>
      <Route path={"/"} element={<Orders />} />
      <Route path={"order-history"} element={<OrderHistory />} />
      <Route path={"settings"} element={<Settings />} />
    </Routes>
  );
}
