// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const DishCategories = {
  "STARTER": "STARTER",
  "SOUP": "SOUP",
  "SALAD": "SALAD",
  "MAINDISH": "MAINDISH",
  "DESSERT": "DESSERT"
};

const TransportationModes = {
  "DRIVING": "DRIVING",
  "BICYCLING": "BICYCLING"
};

const RestaurantCategories = {
  "SUSHI": "SUSHI",
  "TURKISH": "TURKISH",
  "AMERICAN": "AMERICAN",
  "BURGER": "BURGER",
  "ITALIAN": "ITALIAN",
  "INDIAN": "INDIAN",
  "PASTA": "PASTA",
  "JAPANESE": "JAPANESE",
  "VEGAN": "VEGAN",
  "VEGETARIAN": "VEGETARIAN",
  "ASIAN": "ASIAN",
  "MEXICAN": "MEXICAN"
};

const OrderStatus = {
  "NEW": "NEW",
  "COOKING": "COOKING",
  "READY_FOR_PICKUP": "READY_FOR_PICKUP",
  "PICKED_UP": "PICKED_UP",
  "COMPLETED": "COMPLETED",
  "ACCEPTED": "ACCEPTED",
  "DECLINED_BY_RESTAURANT": "DECLINED_BY_RESTAURANT"
};

const { OrderDish, Dish, AppConfig, Basket, BasketDish, Driver, User, Order, Restaurant, DeliveryAddress, PaymentIntent, AddressData } = initSchema(schema);

export {
  OrderDish,
  Dish,
  AppConfig,
  Basket,
  BasketDish,
  Driver,
  User,
  Order,
  Restaurant,
  DeliveryAddress,
  DishCategories,
  TransportationModes,
  RestaurantCategories,
  OrderStatus,
  PaymentIntent,
  AddressData
};