/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { fetchByPath, validateField } from "../../ui-components/utils";
import { AppConfig } from "../../models";

type ModelFields = {
  customerFee: AppConfig["customerFee"];
  deliveryFee: AppConfig["deliveryFee"];
  restaurantFee: AppConfig["restaurantFee"];
  minimumOrderValue: AppConfig["minimumOrderValue"];
};

type Props = {
  clearOnSuccess: boolean;
  onSuccess: (modelFields: ModelFields) => any;
  onError: (modelFields: ModelFields, message: string) => any;
  onSubmit: (modelFields: ModelFields) => any;
  onCancel: () => any;
  onValidate: (modelFields: ModelFields) => any;
  onChange: (modelFields: ModelFields) => any;
};

export default function AdminConfigForm(props: Props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onCancel,
    onValidate,
    onChange,
    ...rest
  } = props;

  const initialValues = {
    customerFee: 0,
    deliveryFee: 0,
    restaurantFee: 0,
    minimumOrderValue: 0,
  };

  const [customerFee, setCustomerFee] = React.useState<
    ModelFields["customerFee"]
  >(initialValues.customerFee);

  const [deliveryFee, setDeliveryFee] = React.useState<
    ModelFields["deliveryFee"]
  >(initialValues.customerFee);

  const [restaurantFee, setRestaurantFee] = React.useState<
    ModelFields["restaurantFee"]
  >(initialValues.restaurantFee);

  const [minimumOrderValue, setMinimumOrderValue] = React.useState<
    ModelFields["minimumOrderValue"]
  >(initialValues.minimumOrderValue);

  const [appConfig, setAdminConfig] = React.useState<AppConfig>();
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    DataStore.query(AppConfig).then((data) => {
      console.log({ data });

      if (data.length > 0) {
        setCustomerFee(data[0].customerFee);
        setDeliveryFee(data[0].deliveryFee);
        setRestaurantFee(data[0].restaurantFee);
        setMinimumOrderValue(data[0].minimumOrderValue);
        setAdminConfig(data[0]);
      }
    });
  }, []);

  const resetStateValues = () => {
    setCustomerFee(appConfig?.customerFee || initialValues.customerFee);
    setDeliveryFee(appConfig?.deliveryFee || initialValues.deliveryFee);
    setRestaurantFee(appConfig?.restaurantFee || initialValues.restaurantFee);
    setMinimumOrderValue(
      appConfig?.minimumOrderValue || initialValues.minimumOrderValue
    );
    setErrors({});
  };
  const validations = {
    customerFee: [{ type: "Required" }],
    deliveryFee: [{ type: "Required" }],
    restaurantFee: [{ type: "Required" }],
    minimumOrderValue: [{ type: "Required" }],
  };
  const runValidationTasks = async (fieldName: string, value: number) => {
    // @ts-ignore
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };

  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields: ModelFields = {
          customerFee,
          deliveryFee,
          restaurantFee,
          minimumOrderValue,
        };
        const validationResponses: { hasError: boolean }[] = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            // @ts-ignore
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                // @ts-ignore
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              // @ts-ignore
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          if (!appConfig) {
            const savedData = await DataStore.save(
              new AppConfig({
                customerFee,
                deliveryFee,
                restaurantFee,
                minimumOrderValue,
              })
            );
            setAdminConfig(savedData);
          } else {
            const updatedData = await DataStore.save(
              AppConfig.copyOf(appConfig, (updated) => {
                updated.customerFee = customerFee;
                updated.deliveryFee = deliveryFee;
                updated.restaurantFee = restaurantFee;
                updated.minimumOrderValue = minimumOrderValue;
              })
            );
            setAdminConfig(updatedData);
          }

          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err: any) {
          if (onError) {
            onError(modelFields, err.message || err);
          }
        }
      }}
      {...rest}
    >
      <TextField
        value={customerFee || 0}
        label="Kundengebühr in %"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        onChange={(e) => {
          let value = Number(e.target.value);
          if (isNaN(value)) {
            setErrors((errors) => ({
              ...errors,
              customerFee: "Value must be a valid number",
            }));
            return;
          }
          if (onChange) {
            const modelFields = {
              customerFee: value,
              deliveryFee,
              restaurantFee,
              minimumOrderValue,
            };
            const result = onChange(modelFields);
            value = result?.customerFee ?? value;
          }
          // @ts-ignore
          if (errors.customerFee?.hasError) {
            runValidationTasks("customerFee", value);
          }
          setCustomerFee(value);
        }}
        onBlur={() =>
          customerFee && runValidationTasks("customerFee", customerFee)
        }
        // @ts-ignore
        errorMessage={errors.customerFee?.errorMessage}
        // @ts-ignore
        hasError={errors.customerFee?.hasError}
      ></TextField>
      <TextField
        value={deliveryFee || 0}
        label="Liefergebühr in €"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        onChange={(e) => {
          let value = Number(e.target.value);
          if (isNaN(value)) {
            setErrors((errors) => ({
              ...errors,
              deliveryFee: "Value must be a valid number",
            }));
            return;
          }
          if (onChange) {
            const modelFields = {
              customerFee,
              deliveryFee: value,
              restaurantFee,
              minimumOrderValue,
            };
            const result = onChange(modelFields);
            value = result?.deliveryFee ?? value;
          }
          // @ts-ignore
          if (errors.deliveryFee?.hasError) {
            runValidationTasks("deliveryFee", value);
          }
          setDeliveryFee(value);
        }}
        onBlur={() =>
          deliveryFee && runValidationTasks("deliveryFee", deliveryFee)
        }
        // @ts-ignore
        errorMessage={errors.deliveryFee?.errorMessage}
        // @ts-ignore
        hasError={errors.deliveryFee?.hasError}
        {...getOverrideProps(null, "deliveryFee")}
      ></TextField>
      <TextField
        value={restaurantFee || 0}
        label="Restaurantgebühr in %"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        onChange={(e) => {
          let value = Number(e.target.value);
          if (isNaN(value)) {
            setErrors((errors) => ({
              ...errors,
              restaurantFee: "Value must be a valid number",
            }));
            return;
          }
          if (onChange) {
            const modelFields = {
              customerFee,
              deliveryFee,
              restaurantFee: value,
              minimumOrderValue,
            };
            const result = onChange(modelFields);
            value = result?.restaurantFee ?? value;
          }
          // @ts-ignore
          if (errors.restaurantFee?.hasError) {
            runValidationTasks("restaurantFee", value);
          }
          setRestaurantFee(value);
        }}
        onBlur={() =>
          restaurantFee && runValidationTasks("restaurantFee", restaurantFee)
        }
        // @ts-ignore
        errorMessage={errors.restaurantFee?.errorMessage}
        // @ts-ignore
        hasError={errors.restaurantFee?.hasError}
      ></TextField>
      <TextField
        value={minimumOrderValue || 0}
        label="Mindestbestellwert in €"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        onChange={(e) => {
          let value = Number(e.target.value);
          if (isNaN(value)) {
            setErrors((errors) => ({
              ...errors,
              minimumOrderValue: "Value must be a valid number",
            }));
            return;
          }
          if (onChange) {
            const modelFields = {
              customerFee,
              deliveryFee,
              restaurantFee,
              minimumOrderValue: value,
            };
            const result = onChange(modelFields);
            value = result?.minimumOrderValue ?? value;
          }
          // @ts-ignore
          if (errors.minimumOrderValue?.hasError) {
            runValidationTasks("minimumOrderValue", value);
          }
          setMinimumOrderValue(value);
        }}
        onBlur={() =>
          minimumOrderValue &&
          runValidationTasks("minimumOrderValue", minimumOrderValue)
        }
        // @ts-ignore
        errorMessage={errors.minimumOrderValue?.errorMessage}
        // @ts-ignore
        hasError={errors.minimumOrderValue?.hasError}
      ></TextField>
      <Flex justifyContent="space-between">
        <Button
          children="Reset"
          type="reset"
          onClick={resetStateValues}
          {...getOverrideProps(null, "ClearButton")}
        ></Button>
        <Flex {...getOverrideProps(null, "RightAlignCTASubFlex")}>
          <Button
            children="Cancel"
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
            {...getOverrideProps(null, "CancelButton")}
          ></Button>
          <Button
            disabled={
              JSON.stringify({
                customerFee: appConfig?.customerFee,
                deliveryFee: appConfig?.deliveryFee,
                restaurantFee: appConfig?.restaurantFee,
                minimumOrderValue: appConfig?.minimumOrderValue,
              }) ===
              JSON.stringify({
                customerFee: customerFee,
                deliveryFee: deliveryFee,
                restaurantFee: restaurantFee,
                minimumOrderValue: minimumOrderValue,
              })
            }
            children="Submit"
            type="submit"
            variation="primary"
            // @ts-ignore
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(null, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
