// Create a context
import React, { createContext, ReactElement, useEffect, useState } from "react";

import { Auth, DataStore } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

import { Restaurant } from "../models";

export interface IAppContext {
  restaurants: Restaurant[] | null;
  setRestaurants: (restaurants: Restaurant[] | null) => void;
  sub?: string;
  loading: boolean;
  authUser: CognitoUser | any;
}

const AppContext: React.Context<{}> = createContext<IAppContext | {}>({});

const AppContextProvider = ({ children }: { children: ReactElement }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [authUser, setAuthUser] = useState<CognitoUser | any>();
  const [restaurants, setRestaurants] = useState<Restaurant[] | null>(null);

  const sub = authUser?.attributes?.sub;

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(setAuthUser);
  }, []);

  useEffect(() => {
    if (!sub) {
      return;
    }

    setLoading(true);

    // fetch Restaurant and filter by adminSub
    DataStore.query(Restaurant)
      .then((restaurants) => setRestaurants(restaurants))
      .finally(() => {
        setTimeout(() => setLoading(false), 500);
      });
  }, [sub]);

  return (
    <AppContext.Provider
      value={{
        restaurants,
        setRestaurants,
        sub,
        loading,
        authUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): IAppContext =>
  React.useContext(AppContext) as IAppContext;

export { AppContext, AppContextProvider };
