import React from "react";
import { Auth } from "aws-amplify";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Divider, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image } from "@aws-amplify/ui-react";

import AdminInfo from "../admin-info";
import { useAppContext } from "../../context/app-context";

export default function SideMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = useAppContext();

  const mainMenuItems: ItemType[] = [
    { key: "/", label: t("app.side-menu.orders") },
    // { key: "menu", label: t("app.side-menu.menu") },
    { key: "order-history", label: t("app.side-menu.order-history") },
    { key: "settings", label: t("app.side-menu.settings") },
  ];

  const menuItems: ItemType[] = [
    ...(appContext.authUser ? mainMenuItems : []),
    // { key: "settings", label: t("app.side-menu.settings") },
    { key: "signOut", label: t("app.side-menu.sign-out"), danger: true },
  ];

  const onClickMenuItem = async (menuItem: ItemType) => {
    if (menuItem?.key === "signOut") {
      await Auth.signOut();
      // window.location.reload();
    } else {
      navigate(`${menuItem?.key}`);
    }
  };

  return (
    <>
      <Image
        alt="tasty-logo"
        src={require("../../assets/logo.png")}
        style={{ margin: 0, padding: 32 }}
      />

      <Divider />

      <AdminInfo />

      <Divider />

      <Menu
        items={menuItems}
        onClick={onClickMenuItem}
        style={{ alignItems: "flex-start" }}
      />
    </>
  );
}
