import React from "react";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { Layout, Typography } from "antd";

import AppRoutes from "./components/app-routes";
import SideMenu from "./components/side-menu";
import { AppContextProvider } from "./context/app-context";

import "./App.css";
import "@aws-amplify/ui-react/styles.css";

function AppProviders() {
  return (
    <AppContextProvider>
      <App />
    </AppContextProvider>
  );
}

function App() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Sider style={styles.sider}>
        <SideMenu />
      </Layout.Sider>

      <Layout>
        <Layout.Header style={styles.header as any}>
          <Typography.Title style={styles.title}>
            Admin Dashboard
          </Typography.Title>
        </Layout.Header>

        <Layout.Content>
          <AppRoutes />
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center" }}>
          Tasty Delivery Admin Dashboard ©2022
        </Layout.Footer>
      </Layout>
    </Layout>
  );
}

const styles = {
  sider: {
    height: "100vh",
    backgroundColor: "white",
  },
  header: {
    backgroundColor: "white",
    textAlign: "center",
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    margin: 0,
    padding: 0,
  },
};

export default withAuthenticator(AppProviders, { hideSignUp: true });
